.site-header {
  display: flex;
  flex-direction: column;
  padding-left: 27px;

  @media (--for-tablet-landscape-up) {
    margin: 0 auto;
    padding: 0 50px;
    max-width: 1440px;
  }
}

:root {
  --logoWidth: 130px;
  --logoHeight: 72px;
}

.site-header__logo {
  position: absolute;
  padding: 10px 12px 12px;
  background-color: var(--primaryColor);
  z-index: 1;

  & > svg {
    display: block;
    width: 73px;
    height: 40px;

    @media (--for-tablet-portrait-up) {
      width: var(--logoWidth);
      height: var(--logoHeight);
    }
  }

  @media (--for-tablet-portrait-up) {
    padding: 14px 22px;
  }

  @media (--for-tablet-landscape-up) {
    margin-left: 27px;
  }
}

.site-header__nav {
  display: none;
  justify-content: flex-end;

  @media (--for-tablet-landscape-down) {
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: color(var(--primaryColor) alpha(-10%));
    padding-top: 10vh;
    justify-content: center;
  }

  &.nav-is-open {
    display: flex;
  }

  @media (--for-tablet-landscape-up) {
    display: flex;
    justify-content: flex-end;
  }
}

.site-header__mobile-nav-btn {
  appearance: none;
  background-color: transparent;
  padding: 14px 12px;
  align-self: flex-end;

  & svg {
    pointer-events: none;
  }

  @media (--for-tablet-landscape-up) {
    display: none;
  }
}

.site-header__mobile-nav-close-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  padding: 10px;
  background-color: transparent;
  appearance: none;

  & > svg {
    fill: var(--secondaryColor);
  }

  @media (--for-tablet-landscape-up) {
    display: none;
  }
}
