.hero {
  position: relative;
  margin: 0;

  @media (--for-tablet-landscape-up) {
    margin: 0 auto;
    max-width: 1440px;
    padding: 0 50px;
  }
}

.hero__caption {
  position: relative;
  margin: -30px 10px 0;
  padding: 14px 10px 18px;
  background-color: rgba(240,174,28,.8);
  color: var(--white);
  font-weight: var(--fontWeightBlack);
  font-style: italic;
  font-size: 22px;

  @media (--for-tablet-portrait-up) {
    position: absolute;
    right: 0;
    bottom: 58px;
    margin: 0;
    max-width: 625px;
    padding: 14px 50px 18px 24px;
    font-size: 32px;
  }

  @media (--for-tablet-landscape-up) {
    max-width: 900px;
    padding: 14px 90px 18px 40px;
    font-size: 44px;
  }
}

.hero__caption-break {
  display: none;

  @media (--for-tablet-portrait-up) {
    display: block;
  }
}
