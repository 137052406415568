.nav-list {
  margin: 0;
  padding: 20px 0 12px;
  display: flex;

  @media (--for-tablet-landscape-down) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.nav-list__item {
  list-style-type: none;


    & + .nav-list__item {
      margin-top: 36px;

      @media (--for-tablet-landscape-up) {
        margin-left: 60px;
        margin-top: 0;
      }
    }
}

.nav-link {
  color: var(--secondaryColor);
  font-weight: var(--fontWeightExtraBold);
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 0.07em;

  @media (--for-tablet-portrait-up) {
    font-size: 24px;
  }

  @media (--for-tablet-landscape-up) {
    font-size: 14px;
  }

  &:hover,
  &:focus {
    @media (--for-tablet-landscape-down) {
      color: color(var(--secondaryColor) shade(30%));
    }
  }
}
