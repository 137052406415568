.site-footer {
  background-color: var(--secondaryColor);
}

.site-footer__main {
  padding: 32px 24px 10px;

  @media (--for-tablet-xl-portrait-up) {
    margin: 0 auto;
    padding: 20px 50px 40px;
    max-width: 1440px;
  }
}

.site-footer__logo {
  width: 244px;
  height: 26px;

  @media (--for-tablet-xl-portrait-up) {
    width: 284px;
    height: 30px;
    order: 1;
  }
}

.client-button {
  display: inline-block;
  border-radius: 8px;
  padding: 18px 24px 16px;
  background-color: var(--doveGray);
  box-shadow: 0 2px 14px 0 rgba(0,0,0,0.5);
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: var(--fontWeightExtraBold);
  font-size: 18px;
  color: var(--secondaryColor);
  min-width: 260px;
  text-align: center;
  margin-bottom: 44px;

  &:hover,
  &:focus {
    color: var(--secondaryColor);
    background-color: color(var(--doveGray) shade(20%));
    outline: 0;
  }

  @media (--for-tablet-xl-portrait-up) {
    margin-bottom: 0;
    order: 2;
  }
}

.site-footer__row {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--for-tablet-xl-portrait-up) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.site-footer__row--header {
  margin-bottom: 18px;

  @media (--for-tablet-xl-portrait-up) {
    margin-bottom: 24px;
  }
}

.site-footer__address {
  text-align: center;
  color: var(--doveGray);
  font-weight: var(--fontWeightExtraBold);
  font-style: normal;
  font-size: 14px;
  margin-bottom: 54px;

  @media (--for-tablet-xl-portrait-up) {
    margin-bottom: 42px;
    font-size: 18px;
    text-align: left;
  }
}

.site-footer__copyright {
  color: var(--doveGray);
  font-weight: var(--fontWeightExtraBold);
  font-size: 14px;
}

.site-footer__nav {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  margin-bottom: 20px;

  @media (--for-tablet-xl-portrait-up) {
    margin-bottom: 0;
  }

}

.site-footer__nav-item {
  color: var(--doveGray);
  & + .site-footer__nav-item::before {
    content: '|';
    margin: 0 20px;
  }
}

.footer-link {
  color: var(--doveGray);
  text-transform: uppercase;
  font-size: 11px;
  font-weight: var(--fontWeightExtraBold);

  @media (--for-tablet-xl-portrait-up) {
    font-size: 14px;
  }
}
