.section-title {
  color: var(--primaryColor);
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;

  @media (--for-tablet-portrait-up) {
    font-size: 34px;
  }
}

.section-title--center {
  text-align: center;
}

.section-blurb {
  font-family: var(--serifFont);
  font-size: 15px;
  color: var(--secondaryColor);
  line-height: 28px;


  @media (--for-tablet-portrait-up) {
    font-size: 24px;
    line-height: 44px;
  }
}

.section-blurb--light {
  color: var(--white);
}
