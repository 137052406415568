html {
  font-family: var(--primaryFont);
  color: var(--black);
  line-height: 1.4;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
