.contact-section {
  background-color: var(--cementGray);
  padding-bottom: 20px;

  @media (--for-tablet-portrait-up) {
    padding-bottom: 40px;
  }
}

.contact-card {
  position: relative;
  margin: -60px auto 0;
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0 2px 34px 0 rgba(0,0,0,0.1);
  max-width: 1200px;
  z-index: 1;

  @media (--for-tablet-portrait-up) {
    margin-top: -90px;
  }
}

.contact-form {
  padding: 24px 10px 20px;

  @media (--for-tablet-portrait-up) {
    padding: 42px 60px 38px;
  }

  & > .section-title {
    margin-bottom: 12px;

    @media (--for-tablet-portrait-up) {
      margin-bottom: 24px;
    }
  }
}

@media (--for-tablet-landscape-up) {
  .contact-form__row {
    display: flex;
    margin-bottom: 20px;

    & > .contact-input {
      flex: 1;

      &:nth-child(2n) {
        margin-left: 40px;
      }
    }
  }
}

.contact-input {
  appearance: none;
  border: 1px solid var(--doveGray);
  border-radius: 8px;
  font-family: var(--serifFont);
  font-size: 22px;
  padding: 14px 10px;
  margin-bottom: 10px;
  width: 100%;

  &::placeholder {
    line-height: 1.5;
  }

  &:focus {
    border-color: var(--primaryColorDark);
    outline: 0;
  }

  &::placeholder {
    font-style: italic;
  }

  @media (--for-tablet-portrait-up) {
    margin-bottom: 20px;
    padding: 20px;
    font-size: 28px;
  }

  @media (--for-tablet-landscape-up) {
    margin-bottom: 0;
  }
}

.contact-button {
  display: block;
  text-align: center;
  margin-top: 10px;
  border: none;
  border-radius: 8px;
  font-weight: var(--fontWeightExtraBold);
  font-size: 20px;
  letter-spacing: 0.3em;
  padding: 12px 20px;
  width: 100%;
  background-color: var(--primaryColor);
  color: var(--white);
  text-transform: uppercase;

  @media (--for-tablet-portrait-up) {
    margin: 0;
    padding: 20px;
    font-size: 24px;
  }

  &:hover,
  &:focus {
    color: var(--white);
    background-color: var(--primaryColorDark);
    outline: 0;
  }
}


.contact-map {
  position: relative;
  height: 315px;
  overflow: hidden;

  @media (--for-tablet-portrait-up) {
    height: 615px;
  }
}

.contact-map__iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
