.contact-list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  @media (--for-tablet-landscape-up) {
    display: flex;
  }
}

.contact-list__item {
  flex: 1;
  border-color: var(--doveGray);
  border-top-width: 1px;
  border-top-style: solid;
  padding: 20px 12px;

  @media (--for-tablet-portrait-up) {
    padding-right: 60px;
    padding-left: 60px;
  }

  @media (--for-tablet-landscape-up) {
    padding: 40px 24px 36px 7%;

    &:first-child {
      border-right-width: 1px;
      border-right-style: solid;
    }
  }
}
