.page {
  padding: 64px 27px;
  min-height: calc(100vh - 250px);

  @media (--for-tablet-portrait-up) {
    padding-top: 110px;
  }

  @media (--for-tablet-landscape-up) {
    margin: 0 auto;
    padding: 110px 50px 110px 77px;
    max-width: 1440px;
  }
}

.page-content {
  max-width: 800px;
}

.page__title {
  margin-bottom: 36px;
}

.page__section-title {
  font-size: 24px;
}

.page__copy {
  & + .page__section-title {
    margin-top: 48px;
  }
}
