.value-card {
  padding: 10px 20px 24px;
  background-color: var(--white);
  box-shadow: 0 2px 34px 0 rgba(0,0,0,0.1);

  @media (--for-tablet-portrait-up) {
    display: flex;
    align-items: center;
    padding: 24px 0;
  }
}

.value-card__icon {
  border-bottom: 1px solid var(--doveGray);
  padding-bottom: 10px;

  @media (--for-tablet-portrait-up) {
    flex: 0 0 auto;
    padding: 12px 0;
    width: 180px;
    border-right: 1px solid var(--doveGray);
    border-bottom: 0;
  }

  & > svg {
    margin: 0 auto;
  }
}

@media (--for-tablet-portrait-down) {
  .value-card__icon > svg { height: auto; }
  .value-card__icon--service > svg { width: 39px; }
  .value-card__icon--relationship > svg { width: 43px; }
  .value-card__icon--teamwork > svg { width: 55px; }
  .value-card__icon--integrity > svg { width: 30px; }
  .value-card__icon--attitude > svg { width: 50px; }
}

.value-card__main {
  @media (--for-tablet-portrait-up) {
    flex: 1;
    padding: 0 100px 0 36px;
  }
}

.value-copy {
  margin: 10px 0 0;
  font-size: 15px;
  line-height: 28px;
  font-family: var(--serifFont);
  color: var(--secondaryColor);

  @media (--for-tablet-portrait-up) {
    margin: 0;
    font-size: 24px;
    line-height: 44px;
  }
}

.value-title {
  font-family: var(--sansFont);
}
