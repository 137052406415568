.services-section {
  padding: 60px 0;

  @media (--for-tablet-portrait-up) {
    padding: 76px 0 116px;
  }

  @media (--for-tablet-landscape-up) {
    margin: 0 auto;
    max-width: 1440px;
  }
}

.services-section__main {
  margin: 0 auto;
  padding: 0 10px;
  max-width: 1440px;

  @media (--for-tablet-landscape-up) {
    padding: 0 50px;
  }

  & > .section-title {
    margin-bottom: 20px;

    @media (--for-tablet-portrait-up) {
      margin-bottom: 48px;
    }

    @media (--for-tablet-landscape-up) {
      margin-bottom: 58px;
    }
  }
}

.service-card-list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  @media (--for-tablet-landscape-up) {
    display: flex;
    justify-content: center;
  }
}

.service-card {
  margin: 0 auto 10px;
  border-radius: 8px;
  background-color: var(--white);
  overflow: hidden;
  box-shadow: 0 2px 34px 0 rgba(0,0,0,0.1);
  max-width: 480px;

  @media (--for-tablet-portrait-up) {
    margin-bottom: 32px;
  }

  @media (--for-tablet-landscape-up) {
    flex: 1;
    margin-bottom: 0;
  }

  &:nth-child(1) {
    @media (--for-tablet-landscape-up) {
      margin-right: -8px;
    }
  }

  &:nth-child(2) {
    @media (--for-tablet-landscape-up) {
      z-index: 1;
      position: relative;
    }
  }

  &:nth-child(3) {
    @media (--for-tablet-landscape-up) {
      margin-left: -8px;
    }
  }
}

@media (--for-tablet-landscape-up) {
  .service-card--recessed {
    margin: 36px 0;
  }
}

.service-card__title {
  padding: 28px 24px;
  background-color: var(--primaryColor);
  color: var(--white);
  font-weight: var(--fontWeightExtraBold);
  text-align: center;
  font-size: 20px;

  @media (--for-tablet-portrait-up) {
    font-size: 24px;
  }
}

.service-card__title--featured {
  @media (--for-tablet-landscape-up) {
    padding-top: 46px;
    padding-bottom: 46px;
  }
}

.services-list {
  margin: 0;
  padding: 16px 0 0;
  list-style-type: none;

  @media (--for-tablet-landscape-up) {
    padding: 32px 0 24px;
  }
}

.services-list__item {
  text-align: center;
  font-family: var(--serifFont);
  font-style: italic;
  color: var(--secondaryColor);
  font-size: 18px;
  margin-bottom: 24px;

  @media (--for-tablet-portrait-up) {
    font-size: 20px;
    margin-bottom: 30px;
  }
}
