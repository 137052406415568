.media-group {
  display: flex;
  align-items: center;
}

.media-group__main {
  margin-left: 24px;

  @media (--for-tablet-portrait-up) {
    margin-left: 40px;
  }
}

.media-group__title {
  margin-bottom: 4px;
  color: var(--secondaryColor);
  text-transform: uppercase;
  font-weight: var(--fontWeightExtraBold);
  font-size: 14px;
  letter-spacing: 0.05em;

  @media (--for-tablet-portrait-up) {
    font-size: 20px;
  }
}

.media-group__link {
  color: var(--secondaryColor);
  font-weight: var(--fontWeightNormal);
  font-style: normal;
  font-size: 30px;
  font-family: var(--serifFont);
  line-height: 1;

  @media (--for-tablet-portrait-up) {
    font-size: 40px;
  }
}

@media (--for-tablet-portrait-down) {
  .media-group__icon > svg { width: 40px; height: auto; }
}
