.mission-section {
  background-color: var(--secondaryColor);
}

.mission-section__main {
  padding: 60px 20px 70px;
  text-align: center;

  @media (--for-tablet-portrait-up) {
    margin: 0 auto;
    padding: 132px 50px;
    max-width: 864px;
  }

  & > .section-title {
    @media (--for-tablet-portrait-up) {
      margin-bottom: 40px;
    }
  }
}
