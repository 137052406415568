.about-section {
  padding: 60px 20px 70px;

  @media (--for-tablet-portrait-up) {
    padding: 100px 75px;
  }

  @media (--for-tablet-landscape-up) {
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 132px 50px;
    max-width: 1440px;
    text-align: left;
  }
}

.about-section__left {
  text-align: center;

  @media (--for-tablet-landscape-up) {
    flex: 2;
    padding-right: 24px;
  }

  & > .section-title {
    @media (--for-tablet-portrait-up) {
      margin-bottom: 40px;
    }
  }
}

.about-section__right {
  @media (--for-tablet-landscape-up) {
    flex: 3;
    padding-right: 80px;
  }
}
