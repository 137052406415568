.values-section {
  background-color: var(--cementGray);
  padding: 0 10px;

  @media (--for-tablet-portrait-up) {
    padding: 0 50px;
  }
}

.values-section__main {
  padding: 60px 0;

  @media (--for-tablet-portrait-up) {
    margin: 0 auto;
    padding: 84px 0;
    max-width: 1200px;
  }

  & > .section-title {
    margin-bottom: 20px;

    @media (--for-tablet-portrait-up) {
      margin-bottom: 70px;
    }
  }
}
